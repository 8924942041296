import axios from 'axios';
import { ApiError, ApiUploadFileError } from 'mid-utils';
import { UploadUrlsResponse } from '../interfaces/cloudStorage';
import text from '../mid-addin-lib.text.json';
import { toFileObject } from './filesystem';
import { UploadURLsPayload, CompleteUploadPayload, DownloadURLPayload, DownloadUrlResponse } from '@adsk/offsite-dc-sdk';
import { DcApiService, inversifyContainer, InversifyTypes } from 'mid-api-services';
import { AccBridgeDownloadUrlQueryParams } from 'mid-api-services';

/**
 * Gets the chunk information for upload taking into account OSS/S3 limitations.
 */
const getChunks = (blob: Blob): any => {
  const MAX_PARTS = 25;
  const MIN_CHUNKSIZE = 0x500000;

  // Just use the blob size if we can upload in a single call.
  // Otherwise, make the chunk size as large as needed but not smaller than the allowed minimum.
  const chunkSize = blob.size <= MIN_CHUNKSIZE ? blob.size : Math.max(MIN_CHUNKSIZE, Math.ceil(blob.size / MAX_PARTS));
  const numChunks = Math.ceil(blob.size / chunkSize);

  return {
    chunkSize,
    numChunks,
  };
};

/** Gets the filename portion of a fully qualyfied file path.
 *
 * @param filePath Fully qualified path to a file.
 * @returns The filename portion of the file path.
 */
// eslint-disable-next-line
const getFileNameFromPath = (filePath: string) => filePath.replace(/^.*(\\|\/|\:)/, '');

/**
 * Data categories
 */
export enum DataCategory {
  Inputs = 'Inputs',
  Outputs = 'Outputs',
  Logs = 'Logs',
  CodeBlocksWorkspace = 'CodeBlocksWorkspace',
  Rules = 'Rules',
}

/**
 * Uploads a file to the cloud.
 *
 * @param tenancyId The tenancy id.
 * @param category The data category.
 * @param filePath The full path of the file to upload.
 * @param contentType Optional content type; defaults to 'application/octet-stream'.
 * @returns The object key with which the file can be downloaded.
 */
export const uploadFile = async (
  tenancyId: string,
  filePath: string,
  category: DataCategory,
  contentType = 'application/octet-stream',
): Promise<string> => {
  try {
    const file: File = await toFileObject(filePath);
    const fileSize = file.size;
    const fileName = getFileNameFromPath(filePath);
    const { chunkSize, numChunks } = getChunks(file);

    const uploadURLsPayload: UploadURLsPayload = {
      fileName,
      numberOfParts: numChunks,
      category,
    };

    // get signed URLs for multi-part upload
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const uploadUrlsResponse: UploadUrlsResponse = await dcApiService.getUploadURLs(tenancyId, uploadURLsPayload);

    // upload the chunks
    const axiosInstance = axios.create({
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });

    for (let i = 0, start = 0; i < numChunks; ++i) {
      const end = Math.min(start + chunkSize, file.size);
      const data: Blob = file.slice(start, end);

      await axiosInstance.put(uploadUrlsResponse.urls[i], data);
      start = end;
    }

    const completeUploadPayload: CompleteUploadPayload = {
      contentType,
      fileName,
      fileSize,
      objectKey: uploadUrlsResponse.objectKey,
      uploadKey: uploadUrlsResponse.uploadKey,
    };

    await dcApiService.completeUpload(tenancyId, completeUploadPayload);
    return uploadUrlsResponse.objectKey;
  } catch (error: unknown) {
    throw new ApiUploadFileError(text.apiRequestError, { error });
  }
};

export const getContentFromObjectKey = async <T>(
  tenancyId: string,
  objectKey: string,
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams,
): Promise<T> => {
  try {
    const downloadURLPayload: DownloadURLPayload = {
      objectKey,
    };
    const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
    const downloadURLResponse: DownloadUrlResponse = await dcApiService.downloadURL({
      projectId: tenancyId,
      downloadURLPayload,
      incomingAccBridgeData,
    });
    const axiosInstance = axios.create();
    const response = await axiosInstance.get(downloadURLResponse.signedUrl);
    return response.data as T;
  } catch (error: unknown) {
    throw new ApiError(text.apiRequestError, { error });
  }
};

export const getSignedUrlFromDownload = async (
  tenancyId: string,
  rfaOutputUrn: string,
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams,
): Promise<string> => {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  const downloadURLPayload = { objectKey: rfaOutputUrn };
  const downloadURLResponse: DownloadUrlResponse = await dcApiService.downloadURL({
    projectId: tenancyId,
    downloadURLPayload,
    incomingAccBridgeData,
  });
  return downloadURLResponse.signedUrl;
};
